<template>
  <div class="container">
    <!-- <GNB @openModal="openModal" /> -->

    <section class="contWrap policy table">
      <article class="full card">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="관리자" name="first" v-if="isSuper">
            <div class="flexB title">
              <h3><span class="admin"></span>관리자 리스트</h3>
              <button class="point" @click="handleUserModal()">
                <em>관리자 등록</em>
              </button>
            </div>
            <div class="tableBox" v-if="userTotal > 0">
              <table class="admin">
                <colgroup>
                  <col style="width: 14%" />
                  <col style="width: 24%" />
                  <col style="width: 24%" />
                  <col style="width: 24%" />
                  <col style="width: 14%" />
                </colgroup>
                <tr>
                  <th>번호</th>
                  <th>권한</th>
                  <th>아이디</th>
                  <th>생성일</th>
                  <th>설정</th>
                </tr>
                <tr v-for="(data, i) in userList" :key="i">
                  <td>{{ data.dataNum }}</td>
                  <td :class="{ primary: data.isSuper }">
                    {{ data.isSuper ? "슈퍼 관리자" : "일반 관리자" }}
                  </td>
                  <td>{{ data.userId }}</td>
                  <td>
                    {{ moment(data.created_at).format("YYYY.MM.DD HH:mm") }}
                  </td>
                  <td>
                    <div class="buttonWrap">
                      <button @click="handleUserModal(true, data._id)">
                        수정
                      </button>
                      <button
                        class="delete"
                        @click="handleDeleteUser(data._id)"
                      >
                        삭제
                      </button>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <div v-else>
              <p>데이터가 없습니다.</p>
            </div>
            <div class="pagination">
              <el-pagination
                v-if="userTotal > 10"
                layout="prev, pager, next"
                :total="userTotal"
                :page-size="10"
                @current-change="userHandleCurrentChange"
              ></el-pagination>
            </div>
          </el-tab-pane>
          <el-tab-pane label="API 관리" name="second">
            <div class="flexB title">
              <h3><span class="api"></span>API 주소 리스트</h3>
              <button class="point" @click="handleApiModal()">
                <em>API 주소 등록</em>
              </button>
            </div>
            <div class="tableBox" v-if="apiTotal > 0">
              <table class="api">
                <colgroup>
                  <col style="width: 10%" />
                  <col style="width: 30%" />
                  <col style="width: 15%" />
                  <col style="width: 15%" />
                  <col style="width: 20%" />
                  <col style="width: 10%" />
                </colgroup>
                <tr>
                  <th>번호</th>
                  <th>API 주소</th>
                  <th>API 종류</th>
                  <th>API 이름</th>
                  <th>생성일</th>
                  <th>상태</th>
                </tr>
                <tr v-for="(data, i) in apiList" :key="i">
                  <td>{{ data.dataNum }}</td>
                  <td>
                    {{ data.apiAddress }}
                  </td>
                  <td>
                    {{ data.apiType }}
                  </td>
                  <td>{{ data.apiName }}</td>
                  <td>
                    {{ moment(data.created_at).format("YYYY.MM.DD HH:mm") }}
                  </td>
                  <td>
                    <button>
                      <span
                        class="material-icons-outlined"
                        @click="handleDeleteApi(data._id)"
                      >
                        delete
                      </span>
                    </button>
                  </td>
                </tr>
              </table>
            </div>
            <div v-else>
              <p>데이터가 없습니다.</p>
            </div>
            <div class="pagination">
              <el-pagination
                v-if="apiTotal > 10"
                layout="prev, pager, next"
                :total="apiTotal"
                :page-size="10"
                @current-change="apiHandleCurrentChange"
              ></el-pagination></div
          ></el-tab-pane>
          <el-tab-pane label="회전 설비 목록" name="third"
            ><div class="flexB title">
              <h3><span class="facility"></span>회전 설비 리스트</h3>
              <button class="point" @click="handleFacilityModal(false, '')">
                <em>회전 설비 등록</em>
              </button>
            </div>

            <article class="facility" v-if="equipmentTotal > 0">
              <div class="list card" :class="{ compressure: data.category=='컴프레셔', pump:data.category=='진공펌프',motor:data.category=='모터' }" v-for="(data, i) in equipmentList" :key="i" @click="handleFacilityModal(true, data._id)">
                <div class="kind">
                  <p>{{ data.category }}</p>
                  <button
                    class="delete"
                    @click.stop="handleDeleteEquipment(data._id)"
                  >
                    <span class="material-icons-round"> remove_circle </span>
                  </button>
                </div>
                <div class="data">
                  <div class="flexB">
                    <p>회전 설비 이름</p>
                    <span>{{ data.equipmentId }}</span>
                  </div>
                  <div class="flexB">
                    <p>회전 설비 종류</p>
                    <span>{{ data.equipmentType }}</span>
                  </div>
                  <div class="flexB">
                    <p>설치 일자</p>
                    <span>{{
                      moment(data.installation_at).format("YYYY.MM.DD")
                    }}</span>
                  </div>
                  <div class="flexB">
                    <p>센서 제품 이름</p>
                    <span>{{
                      data.sensorProduct && data.sensorProduct[0]
                    }}</span>
                  </div>
                </div>
              </div>
            </article>
            <div v-else>
              <p>데이터가 없습니다.</p>
            </div>
            <div class="pagination">
              <el-pagination
                v-if="equipmentTotal > 15"
                layout="prev, pager, next"
                :total="equipmentTotal"
                :page-size="15"
                @current-change="equipmentHandleCurrentChange"
              ></el-pagination>
            </div>
          </el-tab-pane>
        </el-tabs>
      </article>
    </section>
    <transition name="fade">
      <div class="dim" v-if="modalVisible">
        <UserModal
          v-if="userVisible"
          @close="closeUser"
          :editMode="editMode"
          :editId="editId"
        />
        <ApiModal v-if="apiVisible" @close="closeApi" />
        <FacilityModal
          v-if="facilityVisible"
          @close="closeEquipment"
          :editMode="editMode"
          :editId="editId"
        />
        <div class="dimbg" @click="close"></div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapState } from "vuex";

import moment from "moment";
import UserModal from "@/components/pop/UserModal";
import ApiModal from "@/components/pop/ApiModal";
import FacilityModal from "@/components/pop/FacilityModal";
import {
  fetchUserList,
  deleteUser,
  fetchApiList,
  deleteApi,
  fetchEquipmentList,
  deleteEquipment,
} from "@/api/index";

export default {
  components: {
    UserModal,
    ApiModal,
    FacilityModal,
  },
  data() {
    return {
      moment: moment,
      modalVisible: false,
      userVisible: false,
      apiVisible: false,
      activeName: "first",
      userTotal: 0,
      userPage: 1,
      userList: [],
      apiTotal: 0,
      apiPage: 1,
      apiList: [],
      equipmentTotal: 0,
      equipmentPage: 1,
      equipmentList: [],
      total: 0,
      editMode: false,
      editId: "",
      userIsEdit: "",
    };
  },
  computed: {
    ...mapState(["navbarState", "equipment", "isSuper"]),
  },
  created() {
    this.$store.dispatch("SET_NAV_INDEX", "1");
  },
  mounted() {
    this.fetchUserInfo();
    this.fetchApiInfo();
    this.handleSuperUser();
  },
  methods: {
    /**
     * 슈퍼관리자 기능
     */
    handleSuperUser() {
      if (this.isSuper == false) {
        this.activeName = "second";
      }
    },
    /**
     * 관리자 정보 삭제 기능
     */
    handleDeleteUser(id) {
      let result = confirm("회원을 삭제 하시겠습니까?");
      if (result == false) {
        return;
      }
      deleteUser(id).then((res) => {
        if (res.data.status == 200) {
          this.fetchUserInfo();
        } else {
          let message = res.data.message;
          console.log("message", message);
          alert(message);
        }
      });
    },
    /**
     * 관리자 리스트 페이지네이션 기능
     */
    userHandleCurrentChange(val) {
      this.userPage = val;
      this.fetchUserInfo();
    },
    /**
     * 관리자 리스트 가져오기 기능
     */
    fetchUserInfo() {
      let params = {
        total: this.userTotal,
        page: this.userPage,
      };
      fetchUserList(params).then((res) => {
        if (res.data.status == 200) {
          this.userList = res.data.data;
          this.userTotal = res.data.total;
        }
      });
    },
    /**
     * API 정보 삭제 기능
     */
    handleDeleteApi(id) {
      let result = confirm("API를 삭제 하시겠습니까?");
      if (result == false) {
        return;
      }
      deleteApi(id).then((res) => {
        if (res.status == 200) {
          this.fetchApiInfo();
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
    /**
     * API 리스트 페이지네이션 기능
     */
    apiHandleCurrentChange(val) {
      this.apiPage = val;
      this.fetchApiInfo();
    },
    /**
     * API 리스트 가져오기 기능
     */
    fetchApiInfo() {
      let params = {
        total: this.apiTotal,
        page: this.apiPage,
      };
      fetchApiList(params).then((res) => {
        if (res.data.status == 200) {
          this.apiList = res.data.data;
          this.apiTotal = res.data.total;
        }
      });
    },
    /**
     * 설비 정보 삭제 기능
     */
    handleDeleteEquipment(id) {
      this.editMode = undefined;
      this.editId = undefined;
      let result = confirm("설비를 삭제 하시겠습니까?");
      if (result == false) {
        return;
      }
      deleteEquipment(id).then((res) => {
        if (res.status == 200) {
          this.$store.dispatch("getEquipmentInfo");
          localStorage.setItem(
            "equipId",
            this.equipment[this.equipment.length - 1]
              ? this.equipment[this.equipment.length - 1].equipmentId
              : ""
          );
          this.fetchEquipmentInfo();
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
    /**
     * 설비 리스트 페이지네이션 기능
     */
    equipmentHandleCurrentChange(val) {
      this.equipmentPage = val;
      this.fetchEquipmentInfo();
    },
    /**
     * 설비 리스트 가져오기 기능
     */
    fetchEquipmentInfo() {
      let params = {
        total: this.equipmentTotal,
        page: this.equipmentPage,
      };
      fetchEquipmentList(params).then((res) => {
        if (res.data.status == 200) {
          this.equipmentList = res.data.data;
          this.equipmentTotal = res.data.total;
        }
      });
    },
    /**
     * 탭 클릭시 화면 변환 기능
     */
    handleClick() {
      if (this.activeName == "first") {
        this.fetchUserInfo();
      } else if (this.activeName == "second") {
        this.fetchApiInfo();
      } else if (this.activeName == "third") {
        this.fetchEquipmentInfo();
      }
    },
    openModal() {
      this.modalVisible = true;
    },
    closeModal() {
      this.modalVisible = false;
    },
    handleUserModal(editMode, editId) {
      this.editMode = editMode;
      this.editId = editId;
      this.modalVisible = true;
      this.userVisible = true;
      this.facilityVisible = false;
    },
    handleApiModal() {
      this.modalVisible = true;
      this.apiVisible = true;
      this.facilityVisible = false;
    },
    handleFacilityModal(editMode, editId) {
      if (editId != undefined) {
        this.editMode = editMode;
        this.editId = editId;
        this.modalVisible = true;
        this.facilityVisible = true;
      }
    },
    closeUser() {
      this.modalVisible = false;
      this.userVisible = false;
      this.userPage = 1;
      this.fetchUserInfo();
    },
    closeApi() {
      this.modalVisible = false;
      this.apiVisible = false;
      this.apiPage = 1;
      this.fetchApiInfo();
    },
    closeEquipment() {
      this.modalVisible = false;
      this.facilityVisible = false;
      this.equipmentPage = 1;
      this.fetchEquipmentInfo();
    },
    close() {
      this.modalVisible = false;
      this.userVisible = false;
      this.apiVisible = false;
      this.facilityVisible = false;
    },
  },
};
</script>
