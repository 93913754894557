<template>
  <div class="dimbox register user">
    <div class="mHeader">
      <p>관리자 {{ editMode == true ? "수정" : "등록" }}</p>
    </div>
    <div class="mBody">
      <div class="flex">
        <el-radio v-model="isSuper" label="1">일반 관리자</el-radio>
        <el-radio v-model="isSuper" label="2">슈퍼 관리자</el-radio>
      </div>
      <div class="flexB space">
        <p>아이디(이메일) <span class="red">*</span></p>
        <input type="text" v-model="userId" />
      </div>
      <div class="flexB space">
        <p>비밀번호 {{ editMode == true ? " 검증 " : "" }}<span class="red">*</span></p>
        <input type="password" v-model="password" />
      </div>

      <div :class="{ flexB: true, space: true, display: editMode == true }">
        <p>비밀번호 재입력 <span class="red">*</span></p>
        <input type="password" v-model="passwordConfirm" />
      </div>
      <div v-if="error" class="error">
        {{ errMessage }}
      </div>
      <div class="buttonWrap">
        <button @click="close" class="basic close">취소</button>
        <button class="point" @click="edit">
          {{ editMode == true ? "수정" : "등록" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "@/mixins/format";
import { createUser, updateUser, fetchUserInfo } from "@/api/index";
export default {
  mixins: [format],
  props: ["editMode", "editId"],
  data() {
    return {
      userId: "",
      password: "",
      passwordConfirm: "",
      isSuper: "1",
      error: false,
      errMessage: "",
    };
  },
  created() {
    if (this.editMode == true) {
      this.getUserInfo(this.editId);
    }
  },
  mounted() {},
  methods: {
    close() {
      this.$emit("close");
    },
    /**
     * 필요 페이지 데이터 리셋 기능
     */
    reset() {
      this.userId = "";
      this.password = "";
      this.error = false;
      this.errMessage = "";
    },

    /**
     * 관리자 정보 가져오기 기능
     */
    getUserInfo(editId) {
      fetchUserInfo(editId).then((res) => {
        if (res.data.status == 200) {
          this.userId = res.data.data.userId;
          this.isSuper = res.data.data.isSuper == false ? "1" : "2";
          this.password = "";
        } else {
          this.error = true;
          this.errMessage = res.data.message;
        }
      });
    },
    async edit() {
      if (this.editMode == false || this.editMode == undefined) {
        await this.submit();
      } else {
        await this.update();
      }
      if (this.error == false) {
        this.reset();
        this.close();
      }
    },

    /**
     * 관리자 정보 생성 기능
     */
    async submit() {
      let idStr = this.userId.split("@");
      if (this.userId == "") {
        this.error = true;
        this.errMessage = "아이디(이메일)를 입력해주세요.";
        return;
      } else if (this.emailValidate(this.userId) == false) {
        this.error = "emailValidate";
        this.errMessage = "이메일 형식이 올바르지 않습니다.";
        return;
      } else if (this.userId.length > 32) {
        this.error = "emailValidate";
        this.errMessage = "글자수는 32자리를 초과할 수 없습니다.";
        return;
      } else if (this.password == "") {
        this.error = true;
        this.errMessage = "비밀번호를 입력해주세요";
        return;
      } else if (this.checkPassword(this.password) != "") {
        this.error = true;
        this.errMessage = this.checkPassword(this.password);
        return;
      } else if (this.checkSpecial(this.password) == true) {
        this.error = true;
        this.errMessage = "(<, >, ', \")를 제외한 특수문자를 입력해주세요.";
        return;
      } else if (this.pwContinue(this.password) != "") {
        this.error = true;
        this.errMessage = this.pwContinue(this.password);
        return;
      } else if (this.pwSame(this.password) != "") {
        this.error = true;
        this.errMessage = this.pwSame(this.password);
        return;
      } else if (this.password.includes(idStr[0]) == true) {
        this.error = true;
        this.errMessage = "아이디와 중복되는 비밀번호는 사용할 수 없습니다.";
        return;
      } else if (this.passwordConfirm == "") {
        this.error = true;
        this.errMessage = "재입력 비밀번호를 입력해주세요";
        return;
      } else if (this.checkPassword(this.passwordConfirm) != "") {
        this.error = true;
        this.errMessage = this.checkPassword(this.passwordConfirm);
        return;
      } else if (this.checkSpecial(this.passwordConfirm) == true) {
        this.error = true;
        this.errMessage = "(<, >, ', \")를 제외한 특수문자를 입력해주세요.";
        return;
      } else if (this.pwContinue(this.passwordConfirm) != "") {
        this.error = true;
        this.errMessage = this.pwContinue(this.passwordConfirm);
        return;
      } else if (this.pwSame(this.passwordConfirm) != "") {
        this.error = true;
        this.errMessage = this.pwSame(this.passwordConfirm);
        return;
      } else if (this.passwordConfirm.includes(idStr[0]) == true) {
        this.error = true;
        this.errMessage = "아이디와 중복되는 비밀번호는 사용할 수 없습니다.";
        return;
      } else if (this.password != this.passwordConfirm) {
        this.error = true;
        this.errMessage = "비밀번호가 일치하지 않습니다.";
        return;
      }
      let data = {
        userId: this.userId,
        password: this.password,
        isSuper: this.isSuper == 1 ? false : true,
      };
      await createUser(data).then((res) => {
        if (res.data.status != 200) {
          this.error = true;
          this.errMessage = res.data.message;
        } else {
          this.error = false;
        }
      });
    },
    /**
     * 관리자 정보 수정 기능
     */
    async update() {
      if (this.userId == "") {
        this.error = true;
        this.errMessage = "아이디(이메일)를 입력해주세요.";
        return;
      } else if (this.emailValidate(this.userId) == false) {
        this.error = "emailValidate";
        this.errMessage = "이메일 형식이 올바르지 않습니다.";
        return;
      } else if (this.userId.length > 32) {
        this.error = "emailValidate";
        this.errMessage = "글자수는 32자리를 초과할 수 없습니다.";
        return;
      } else if (this.password == "") {
        this.error = true;
        this.errMessage = "비밀번호를 입력해주세요";
        return;
      } else if (this.checkPassword(this.password) != "") {
        this.error = true;
        this.errMessage = this.checkPassword(this.password);
        return;
      } 
      let data = {
        _id: this.editId,
        userId: this.userId,
        password: this.password,
        isSuper: this.isSuper == 1 ? false : true,
      };
      await updateUser(data).then((res) => {
        if (res.data.status != 200) {
          this.error = true;
          this.errMessage = res.data.message;
        } else {
          this.error = false;
        }
      });
    },
  },
};
</script>
<style scoped>
.display {
  display: none !important;
}
</style>
