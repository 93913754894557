<template>
  <div class="dimbox register user scroll">
    <div class="mHeader">
      <p>회전 설비 등록</p>
    </div>
    <div class="mBody">
      <div>
        <div class="flexB space">
          <p>설비 종류 <span class="red">*</span></p>
          <el-select v-model="category" placeholder="설비종류">
            <el-option
              v-for="item in opt"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="flexB space">
          <p>회전 설비 이름 <span class="red">*</span></p>
          <input type="text" v-model="equipmentId" />
        </div>
        <div class="flexB space">
          <p>회전 설비 종류 <span class="red">*</span></p>
          <input type="text" v-model="equipmentType" />
        </div>
        <div class="flexB space">
          <p>회전 설비 설치 일자 <span class="red">*</span></p>
          <el-date-picker
            v-model="installation_at"
            type="date"
            placeholder="날짜를 선택해주세요"
          >
          </el-date-picker>
        </div>
        <div class="flexB multiple space">
          <p>센서 제품 이름 <span class="red">*</span></p>
          <div>
            <div class="inputBox" v-for="(item, i) in options" :key="i">
              <div>
                <input type="text" v-model="item.sensorProduct" />
                <button class="remove" v-if="i > 0">
                  <span
                    class="material-icons-outlined"
                    @click="handleSensor('minus', i)"
                  >
                    remove
                  </span>
                </button>
                <button class="add" v-else>
                  <span
                    class="material-icons-outlined"
                    @click="handleSensor('add')"
                  >
                    add
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="flexB space">
          <p>이상감지 예측 API <span class="red">*</span></p>
          <input
            type="text"
            v-model="sensorApiIp"
            placeholder="ex) http://localhost:0000"
          />
        </div>
        <div class="flexB space">
          <p>네트워크 트래픽 API <span class="red">*</span></p>
          <input
            type="text"
            v-model="networkApiIp"
            placeholder="ex) http://localhost:0000"
          />
        </div>
        <div class="flexB space">
          <p>네트워크 트래픽 현황 API <span class="red">*</span></p>
          <input
            type="text"
            v-model="networkStatusApiIp"
            placeholder="ex) http://localhost:0000"
          />
        </div>
        <div class="flexB space">
          <p>이메일 등록 개수</p>
          <input type="number" v-model="email" min="1" max="3" @input="() => { if(email > 3 || email < 1) { email = 3 }}"/>
        </div>
        <div class="flexB space">
          <p>연락처 등록 개수</p>
          <input type="number" v-model="sms" min="1" max="3" @input="() => { if(sms > 3 || sms < 1) { sms = 3 }}"/>
        </div>
        <div v-if="error" class="error">
          {{ errMessage }}
        </div>
      </div>
    </div>
    <div class="mFooter">
      <div class="buttonWrap">
        <button @click="close" class="basic close">취소</button>
        <button class="point" @click="edit">
          {{ editMode == true ? "수정" : "등록" }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import {
  createEquipment,
  fetchEquipmentInfo,
  updateEquipment,
} from "@/api/index";
import { format } from "@/mixins/format";
export default {
  props: ["editMode", "editId"],
  mixins: [format],
  data() {
    return {
      error: false,
      errMessage: "",
      category: "",
      equipmentId: "",
      equipmentType: "",
      installation_at: "",
      sensorProduct: [],
      sensorApiIp: "",
      networkApiIp: "",
      networkStatusApiIp: "",
      sensorPort: "",
      networkProt: "",
      options: [{ sensorProduct: "" }],
      email: 1,
      sms: 1,
      opt: [
        {
          value: "컴프레셔",
          label: "컴프레셔",
        },
        {
          value: "모터",
          label: "모터",
        },
        {
          value: "진공펌프",
          label: "진공펌프",
        },
      ],
    };
  },
  created() {
    if (this.editMode == true) {
      this.getEquipmentInfo(this.editId);
    }
  },
  mounted() {},
  methods: {
    close() {
      this.$emit("close");
    },
    /**
     * 센서 제품 추가 기능
     */
    handleSensor(opt, index) {
      if (this.options.length < 5) {
        if (opt == "add") {
          this.options.push({ sensorProduct: "" });
        } else if (opt == "minus") {
          this.options.splice(index, 1);
        }
      } else if (this.options.length == 5) {
        if (opt == "minus") {
          this.options.splice(index, 1);
        }
      }
    },
    async edit() {
      if (this.editMode == false || this.editMode == undefined) {
        await this.submit();
      } else {
        await this.update();
      }
      if (this.error == false) {
        this.close();
      }
    },
    /**
     * 설비 생성 기능
     */
    async submit() {
      this.sensorProduct = [];
      this.options.map((item) => {
        if (item.sensorProduct != "") {
          this.sensorProduct.push(item.sensorProduct);
        }
      });
      if (this.category == "") {
        this.error = true;
        this.errMessage = "설비종류를 입력해주세요.";
        return;
      } else if (this.equipmentId == "") {
        this.error = true;
        this.errMessage = "회전 설비 이름을 입력해주세요";
        return;
      } else if (this.validateLength(4, 16, this.equipmentId) == false) {
        this.error = true;
        this.errMessage = "회전 설비 이름은 4자리에서 16자리 이내로 입력해주세요.";
        return;
      } else if (this.equipmentType == "") {
        this.error = true;
        this.errMessage = "회전 설비 종류를 입력해주세요";
        return;
      } else if (this.validateLength(4, 16, this.equipmentType) == false) {
        this.error = true;
        this.errMessage = "회전 설비 종류는 4자리에서 16자리 이내로 입력해주세요.";
        return;
      } else if (this.installation_at == "") {
        this.error = true;
        this.errMessage = "회전 설비 설치 일자 입력해주세요";
        return;
      } else if (this.sensorProduct && this.sensorProduct[0] == undefined) {
        this.error = true;
        this.errMessage = "센서 제품 이름을 하나 이상 입력해주세요";
        return;
      } else if (this.listLengthValidate(this.sensorProduct) == false) {
        this.error = true;
        this.errMessage = "센서 제품 이름은 4자리에서 16자리 이내로 입력해주세요.";
        return;
      } else if (this.sensorApiIp == "") {
        this.error = true;
        this.errMessage = "이상감지 예측 API를 입력해주세요";
        return;
      } else if (this.urlTest(this.sensorApiIp) == false) {
        this.error = true;
        this.errMessage = "이상감지 예측 API 주소입력 형식을 확인해주세요.";
        return;
      } else if (this.validateLength(8, 256, this.sensorApiIp) == false) {
        this.error = true;
        this.errMessage = "이상감지 예측 API 주소는 8자리에서 256자리 이내로 입력해주세요.";
        return;
      } else if (this.networkApiIp == "") {
        this.error = true;
        this.errMessage = "네트워크 트래픽 API를 입력해주세요";
        return;
      } else if (this.urlTest(this.networkApiIp) == false) {
        this.error = true;
        this.errMessage = "네트워크 트래픽 API 주소입력 형식을 확인해주세요.";
        return;
      } else if (this.validateLength(8, 256, this.networkApiIp) == false) {
        this.error = true;
        this.errMessage = "네트워크 트래픽 API 주소는 8자리에서 256자리 이내로 입력해주세요.";
        return;
      } else if (this.networkStatusApiIp == "") {
        this.error = true;
        this.errMessage = "네트워크 트래픽 현황 API를 입력해주세요";
        return;
      } else if (this.urlTest(this.networkStatusApiIp) == false) {
        this.error = true;
        this.errMessage =
          "네트워크 트래픽 현황 API 주소입력 형식을 확인해주세요.";
        return;
      } else if (this.validateLength(8, 256, this.networkStatusApiIp) == false) {
        this.error = true;
        this.errMessage = "네트워크 트래픽 현황 API는 8자리에서 256자리 이내로 입력해주세요.";
        return;
      }
      let data = {
        category: this.category,
        equipmentId: this.equipmentId,
        equipmentType: this.equipmentType,
        installation_at: this.installation_at,
        sensorProduct: this.sensorProduct,
        sensorApiIp: this.sensorApiIp,
        networkApiIp: this.networkApiIp,
        networkStatusApiIp: this.networkStatusApiIp,
        email: this.email,
        sms: this.sms,
      };
      await createEquipment(data).then((res) => {
        if (res.data.status != 200) {
          this.error = true;
          this.errMessage = res.data.message;
        } else {
          this.$store.dispatch("getEquipmentInfo");
          this.error = false;
        }
      });
    },
    /**
     * 센서제품이름 list data 검증하기
     */
    listLengthValidate(list) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].length < 4 || list[i].length > 16) {
          return false;
        }
      }
      return true;
    },
    /**
     * 설비 정보 수정 기능
     */
    async update() {
      this.sensorProduct = [];
      this.options.map((item) => {
        if (item.sensorProduct != "") {
          this.sensorProduct.push(item.sensorProduct);
        }
      });
      if (this.category == "") {
        this.error = true;
        this.errMessage = "설비종류를 입력해주세요.";
        return;
      } else if (this.equipmentId == "") {
        this.error = true;
        this.errMessage = "회전 설비 이름을 입력해주세요";
        return;
      } else if (this.validateLength(4, 16, this.equipmentId) == false) {
        this.error = true;
        this.errMessage = "회전 설비 이름은 4자리에서 16자리 이내로 입력해주세요.";
        return;
      } else if (this.equipmentType == "") {
        this.error = true;
        this.errMessage = "회전 설비 종류를 입력해주세요";
        return;
      } else if (this.validateLength(4, 16, this.equipmentType) == false) {
        this.error = true;
        this.errMessage = "회전 설비 종류는 4자리에서 16자리 이내로 입력해주세요.";
        return;
      } else if (this.installation_at == "") {
        this.error = true;
        this.errMessage = "회전 설비 설치 일자 입력해주세요";
        return;
      } else if (this.sensorProduct && this.sensorProduct[0] == undefined) {
        this.error = true;
        this.errMessage = "센서 제품 이름을 하나 이상 입력해주세요";
        return;
      } else if (this.listLengthValidate(this.sensorProduct) == false) {
        this.error = true;
        this.errMessage = "센서 제품 이름은 4자리에서 16자리 이내로 입력해주세요.";
        return;
      } else if (this.sensorApiIp == "") {
        this.error = true;
        this.errMessage = "이상감지 예측 API를 입력해주세요";
        return;
      } else if (this.urlTest(this.sensorApiIp) == false) {
        this.error = true;
        this.errMessage = "이상감지 예측 API 주소입력 형식을 확인해주세요.";
        return;
      } else if (this.validateLength(8, 256, this.sensorApiIp) == false) {
        this.error = true;
        this.errMessage = "이상감지 예측 API 주소는 8자리에서 256자리 이내로 입력해주세요.";
        return;
      } else if (this.networkApiIp == "") {
        this.error = true;
        this.errMessage = "네트워크 트래픽 API를 입력해주세요";
        return;
      } else if (this.urlTest(this.networkApiIp) == false) {
        this.error = true;
        this.errMessage = "네트워크 트래픽 API 주소입력 형식을 확인해주세요.";
        return;
      } else if (this.validateLength(8, 256, this.networkApiIp) == false) {
        this.error = true;
        this.errMessage = "네트워크 트래픽 API 주소는 8자리에서 256자리 이내로 입력해주세요.";
        return;
      } else if (this.networkStatusApiIp == "") {
        this.error = true;
        this.errMessage = "네트워크 트래픽 현황 API를 입력해주세요";
        return;
      } else if (this.urlTest(this.networkStatusApiIp) == false) {
        this.error = true;
        this.errMessage =
          "네트워크 트래픽 현황 API 주소입력 형식을 확인해주세요.";
        return;
      } else if (this.validateLength(8, 256, this.networkStatusApiIp) == false) {
        this.error = true;
        this.errMessage = "네트워크 트래픽 현황 API는 8자리에서 256자리 이내로 입력해주세요.";
        return;
      }
      let data = {
        _id: this.editId,
        category: this.category,
        equipmentId: this.equipmentId,
        equipmentType: this.equipmentType,
        installation_at: this.installation_at,
        sensorProduct: this.sensorProduct,
        sensorApiIp: this.sensorApiIp,
        networkApiIp: this.networkApiIp,
        networkStatusApiIp: this.networkStatusApiIp,
        email: this.email,
        sms: this.sms,
      };
      await updateEquipment(data).then((res) => {
        if (res.data.status != 200) {
          this.error = true;
          this.errMessage = res.data.message;
        } else {
          this.error = false;
        }
      });
    },
    /**
     * 설비 정보 가져오기 기능
     */
    getEquipmentInfo(editId) {
      fetchEquipmentInfo(editId).then((res) => {
        if (res.data.status == 200) {
          this.options.pop();
          this.category = res.data.data.category;
          this.equipmentId = res.data.data.equipmentId;
          this.equipmentType = res.data.data.equipmentType;
          this.installation_at = res.data.data.installation_at;
          this.sensorApiIp = res.data.data.sensorApiIp;
          this.networkApiIp = res.data.data.networkApiIp;
          this.networkStatusApiIp = res.data.data.networkStatusApiIp;
          this.email = res.data.data.email;
          this.sms = res.data.data.sms;
          res.data.data.sensorProduct.map((item) => {
            this.options.push({ sensorProduct: item });
          });
        } else {
          this.error = true;
          this.errMessage = res.data.message;
        }
      });
    },
  },
};
</script>
