<template>
  <div class="dimbox register user">
    <div class="mHeader">
      <p>API 주소 등록</p>
    </div>
    <div class="mBody">
      <div class="flexB space">
        <p>API 주소 <span class="red">*</span></p>
        <input type="text" v-model="apiAddress" placeholder="ex) http://localhost" />
      </div>
      <div class="flexB space">
        <p>API 이름 <span class="red">*</span></p>
        <input type="text" v-model="apiName" />
      </div>
      <div class="flexB space">
        <p>API 종류 <span class="red">*</span></p>
        <el-select v-model="apiType" placeholder="회전설비명">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div v-if="error" class="error">
        {{ errMessage }}
      </div>
      <div class="buttonWrap">
        <button @click="close" class="basic close">취소</button>
        <button class="point" @click="submit">등록</button>
      </div>
    </div>
  </div>
</template>
<script>
import { format } from "@/mixins/format";
import { createApi, fetchEquipmentListAll } from "@/api/index";
export default {
  mixins: [format],
  data() {
    return {
      apiType: "",
      apiName: "",
      apiAddress: "",
      error: false,
      errMessage: "",
      options: [],
      // [
      //   { value: "설비", label: "설비" },
      //   { value: "스마트센서", label: "스마트센서" },
      //   { value: "로컬센서", label: "로컬센서" },
      // ],
    };
  },
  mounted() {
    this.fetchEquipmentInfo();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    /**
     * 회절 설비 리스트
     */
    fetchEquipmentInfo() {
      let params = {
        total: this.equipmentTotal,
        page: this.equipmentPage,
      };
      fetchEquipmentListAll(params).then((res) => {
        console.log("회전설비명", res.data);
        if (res.data.status == 200) {
          this.equipmentList = res.data.data;
          this.options = res.data.data.map((res) => {
            return { value: res.equipmentId, label: res.equipmentId };
          });
          this.equipmentTotal = res.data.total;
        }
      });
    },
    /**
     * API 정보 생성
     */
    async submit() {
      if (this.apiAddress == "") {
        this.error = true;
        this.errMessage = "API주소를 입력해주세요.";
        return;
      } else if (this.validateLength(8, 256, this.apiAddress) == false) {
        this.error = true;
        this.errMessage = "API주소는 8자리에서 256자리 이내로 입력해주세요.";
        return;
      } else if (this.urlTest(this.apiAddress) == false) {
        this.error = true;
        this.errMessage = "API 주소입력 형식을 확인해주세요.";
        return;
      } else if (this.apiName == "") {
        this.error = true;
        this.errMessage = "API이름을 입력해주세요.";
        return;
      } else if (this.validateLength(4, 16, this.apiName) == false) {
        this.error = true;
        this.errMessage = "API이름은 4자리에서 16자리 이내로 입력해주세요.";
        return;
      } else if (this.apiType == "") {
        this.error = true;
        this.errMessage = "API 타입을 설정해주세요.";
        return;
      }
      let data = {
        apiAddress: this.apiAddress,
        apiName: this.apiName,
        apiType: this.apiType,
      };
      await createApi(data).then((res) => {
        if (res.data.status != 200) {
          this.error = true;
          this.errMessage = res.data.message;
        } else {
          this.error = false;
        }
      });
      if (this.error == false) {
        this.close();
      }
    },
  },
};
</script>
